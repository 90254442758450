<template>
  <div class="progress-bar">
    <div class="bar" :style="{ width: progress + '%' } "></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: { type: Number, required: true }
  }
};
</script>

<style lang="scss" scoped>
.progress-bar {
  background-color: $neutrals-charcoal;
  border-radius: 20px;
  width: 100%;
}

.bar {
  background-color: $secondary;
  border-radius: 20px;
  height: 40px;
  width: 10%;
  line-height: 40px;
  color: $neutrals-white;
  text-align: center;
}
</style>
